import { useQueryClient } from '@tanstack/react-query'
import clsx from 'clsx'
import maxBy from 'lodash/maxBy'
import orderBy from 'lodash/orderBy'
import { useState } from 'react'

import { FvDate, Icon, Tooltip } from '@fv/client-components'
import { type AdminCarrierUser } from '@fv/models'
import { type DTO } from '@fv/models/core'
import { AdminSliderPanel } from '@/components/AdminSliderPanel'
import { AdminButton } from '@/components/shared/AdminButton'
import { AdminLink } from '@/components/shared/AdminLink'
import { AdminTable } from '@/components/shared/AdminTable'
import { CopyButton } from '@/components/shared/CopyButton'
import { carrierUri } from '@/constants'
import { usersQueryKeys } from '@/hooks/useFetchUsers'
import { useRevokeSession } from '@/hooks/useRevokeSession'

import { NO_EXPORT_ATTR } from '../reports/helpers'
import { ViewAsLink } from './ViewAsLink'

type Props = {
  users: DTO<AdminCarrierUser>[]
  showCarrierCol?: boolean
  sortable?: boolean
}

export const CarrierUsersTable = ({
  users,
  showCarrierCol,
  sortable,
}: Props) => {
  const [currentUser, setCurrentUser] = useState<DTO<AdminCarrierUser>>()
  const revokeSession = useRevokeSession()
  const queryClient = useQueryClient()

  if (!users?.length) return null

  const handleSessionRevoke = async (id: string) => {
    if (window.confirm('Are you sure you want to revoke this session?')) {
      await revokeSession.mutateAsync(id)

      setCurrentUser(p => ({
        ...p,
        sessions: p.sessions.filter(s => s._id !== id),
      }))

      queryClient.invalidateQueries(usersQueryKeys.all)
    }
  }

  return (
    <>
      <AdminTable
        rowKey={u => u._id}
        rowClassName={() => 'leading-snug [&>td]:align-top'}
        data={users}
        disableSort={!sortable}
        columns={[
          {
            key: 'isVerified',
            label: '',
            render: ({ isVerified }) => (
              <Tooltip label={isVerified ? 'Verified' : 'Not verified'}>
                <Icon
                  icon={isVerified ? 'check-circle' : 'clock'}
                  className={clsx({
                    'text-success': isVerified,
                    'text-warning': !isVerified,
                  })}
                />
              </Tooltip>
            ),
          },
          {
            key: 'email',
            label: 'Email',
            render: user => (
              <div className="flex flex-col">
                {user.email}

                <small {...{ [NO_EXPORT_ATTR]: true }}>
                  {user.profile?.firstName} {user.profile?.lastName}{' '}
                  {user.profile?.role ? `| ${user.profile.role}` : ''}
                </small>
                <small {...{ [NO_EXPORT_ATTR]: true }}>{user._id}</small>
                <div className="flex justify-between">
                  <ViewAsLink className="text-xs" userId={user._id}>
                    View as
                  </ViewAsLink>
                  {!!user.activeRegistration && (
                    <div
                      className="flex items-end"
                      {...{ [NO_EXPORT_ATTR]: true }}
                    >
                      <CopyButton
                        value={`${carrierUri}/login/${user.activeRegistration.token}`}
                        className="btn-link btn-xs normal-case weight font-normal"
                        noOutline
                        icon={null}
                      >
                        Copy Login
                      </CopyButton>
                    </div>
                  )}
                </div>
              </div>
            ),
          },
          ...(showCarrierCol
            ? [
                {
                  key: 'carrier',
                  label: 'Carrier',
                  render: ({ carrier }: DTO<AdminCarrierUser>) =>
                    !!carrier && (
                      <div className="flex flex-col">
                        <div>
                          <AdminLink to={`/carriers/${carrier._id}/overview`}>
                            {carrier.name}
                          </AdminLink>
                        </div>
                        <small>{carrier._id}</small>
                        {carrier.code && (
                          <span className="badge badge-sm badge-outline">
                            {carrier.code}
                          </span>
                        )}
                      </div>
                    ),
                },
              ]
            : []),
          {
            key: 'accounts',
            label: 'Accounts',
            render: ({ accounts }) => (
              <>
                {(accounts ?? [])
                  .map<React.ReactNode>(a => (
                    <>
                      <AdminLink to={`/accounts/${a?._id}/overview`}>
                        {a?.name}
                      </AdminLink>
                    </>
                  ))
                  .reduce<Array<React.ReactNode>>(
                    (prev, curr) =>
                      prev === null ? [curr] : [...prev, ', ', curr],
                    null,
                  )}
              </>
            ),
          },
          {
            key: 'created',
            label: 'Created',
            render: user => (
              <FvDate utc val={user.created} format="MM/DD/YYYY hh:mm A" />
            ),
          },
          {
            key: 'lastLogin',
            label: 'Last activity',
            sort: (users, dir) =>
              orderBy(
                users,
                u => maxBy(u.sessions, s => s.lastUsed)?.lastUsed,
                dir,
              ),
            render: user => (
              <div className="flex flex-col">
                <FvDate
                  utc
                  val={maxBy(user.sessions, s => s.lastUsed)?.lastUsed}
                  format="MM/DD/YYYY hh:mm A"
                />
                <AdminLink
                  className="btn-xs p-0"
                  onClick={() => setCurrentUser(user)}
                  {...{ [NO_EXPORT_ATTR]: true }}
                >
                  View sessions
                </AdminLink>
              </div>
            ),
          },
        ]}
      />
      <AdminSliderPanel
        isOpen={!!currentUser}
        closePanel={() => setCurrentUser(null)}
        title={`${currentUser?.email} sessions`}
      >
        {!!currentUser && (
          <AdminTable
            emptyContent={
              <div className="text-center mt-4">
                <Icon icon="ban" /> No sessions for this user
              </div>
            }
            rowKey={s => s._id}
            columns={[
              {
                key: '_id',
                label: 'Session Id',
                render: s => s._id,
              },
              {
                key: 'lastUsed',
                render: s => (
                  <FvDate val={s.lastUsed} format="YYYY-MM-DD h:mm A" utc />
                ),
              },
              {
                key: 'revoke',
                label: '',
                render: s => (
                  <AdminButton
                    onClick={() => handleSessionRevoke(s._id)}
                    className="btn-xs btn-error"
                    icon="trash"
                  />
                ),
              },
            ]}
            data={currentUser?.sessions ?? []}
          />
        )}
      </AdminSliderPanel>
    </>
  )
}
